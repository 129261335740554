import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const STORE_BRAND_STATUS = {
  SHARED: 1,
  NOT_SHARED: 2,
  NO_ACCESS: 3,
  BLOCK: 4
};

// Map status values to human-readable labels
const statusLabels: { [key: number]: string } = {
  1: 'Shared',
  2: 'Not Shared',
  3: 'No Access',
  4: 'Blocked'
};

const StoreBrandStatusRadio = ({ status, onStatusChange, rowId, defaultValue, disabled }: { status: number, onStatusChange: (id: string, status: number) => void, rowId: string, defaultValue: number, disabled: boolean }) => {
  return (
    <div className='p-2'>
      {Object.keys(STORE_BRAND_STATUS).map((statusKey) => {
        const statusValue = STORE_BRAND_STATUS[statusKey as keyof typeof STORE_BRAND_STATUS];
        return (
          <div className={`form-check form-check-inline mb-1 w-100 ${(status || defaultValue) === statusValue ? "fw-600" : "fw-400 text-muted"}`}style={{ display: "block" }} key={statusValue}>
            <input
              className={`form-check-input`}
              type="radio"
              name={`status-${rowId}`}
              id={`status-${rowId}-${statusValue}`}
              value={statusValue}
              checked={(status || defaultValue) === statusValue}
              onChange={() => onStatusChange(rowId, statusValue)}
              disabled={disabled}
            />
            <label className="form-check-label w-100" htmlFor={`status-${rowId}-${statusValue}`}>
              {statusLabels[statusValue]}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default StoreBrandStatusRadio;
