import React, { useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import service from '../../../../api/services'
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import constant from '../../../../constants/constant';
import { findConstantLabel } from '../../../../constants/utils';
const { AddBrand } = organisms
const AddBrands = () => {
    let params = useParams();
    let brandEditID = params.id || ''
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [productTypeList, setProductTypeList] = useState([])
    const [createClick, setCreateClick] = useState(false)
    const [productSelectDropdown, setProductSelectDropdown] = useState([])
    const [brandCategory, setBrandCategory]=useState<number>(constant.BRAND_CATEGORY[0].value)
    const [productTypeId, setProductTypeId]=useState<{label:string, value:string}[]>([])
    const [brandData, setBrandData] = useState({
        brand_logo: '',
        brand_name: "",
        brand_code: "",
        owner_name: "",
      
    })
    const [components, setComponents] = useState([
        {
            brand_margin: '',
            brand_discount: '',
            brand_landing_cost: '',
            specx_landing_cost: '',
            product_type_id: ''
        }])
    const getProductType = async () => {
        try {
            let response = await service.productData.productTypeListingService({})
            if (response?.status === 200) {
                setProductTypeList(getSelectDropdown(response.data.data))
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectDropdown = (response: any) => {
        let temp: any = []
        response && response?.map((item: any) => {
            temp.push({
                "label": item.name,
                "value": item.id
            })
        })
        return temp
    }
    useEffect(() => {
        if (createClick) {
            addBrand()
        } else {
            getProductType()
        }
        if (brandEditID) {
            getBrandDataById()
        }
        setCreateClick(false)
    }, [createClick])

    const addBrand = async () => {
        const productTypeIds = productTypeId.map((item,index:number)=>(
            {
                brand_margin: 0,
                brand_discount: 0,
                brand_landing_cost: 0,
                specx_landing_cost: 0,
                product_type_id: item?.value
            }
       ) )
        const payload={
            brand_category:brandCategory,
            brand_logo: brandData?.brand_logo,
            brand_name: brandData?.brand_name,
            brand_code: brandData?.brand_code,
            owner_name: "",
            product_types: productTypeIds

        }

        const apiPayload= (brandCategory === constant.BRAND_CATEGORY[1].value) ? payload : {brand_category:brandCategory,...brandData}
        try {
            let response
            if (brandEditID) {
                if (brandData) {
                    response = await service.productData.updateBrandService(brandEditID, apiPayload)
                    if (response?.status === 200) {
                        addToast('brand Update Successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                        navigate(-1)
                    }
                } else {
                    addToast("Please Select Atleast 1 Product", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                }
            } else {
                response = await service.productData.createBrandService(apiPayload)
                if (response?.status === 201) {
                    addToast('brand Create Successfully', {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    navigate(-1)
                }
            }

        } catch (error: any) {
            console.log(error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const getBrandDataById = async () => {
        try {
            let response = await service.productData.getBrandByIdService(brandEditID)
            handleEditData(response.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    const getProductIdDropDown=(response:[], name:string):{label:string,value:string}[]=>{
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value.product_type.id,
                "label": value.product_type.name,
            }
            )
        })
        return dropdown
    }

    const handleEditData = (response: any) => {
        let tempSelect: any = []
        let tempEditData: any = {
            brand_logo: response.brand_logo,
            brand_name: response.brand_name,
            brand_code: response.brand_code,
            owner_name: response.owner_name,
            product_types: []
        }
        response.product_types && response.product_types.map((data: any) => {
            tempSelect.push({
                "label": data?.product_type?.name || '',
                "value": data?.product_type?.id || ''
            })
            tempEditData.product_types.push({
                brand_margin: data.brand_margin,
                brand_discount: data.brand_discount,
                brand_landing_cost: data.brand_landing_cost,
                specx_landing_cost: data.specx_landing_cost,
                product_type_id: data?.product_type?.id || ''
            })
        })
        setProductTypeId(getProductIdDropDown(response.product_types,"product_type?.id"))
        setProductSelectDropdown(tempSelect)
        setComponents(tempEditData.product_types)
        setBrandData(tempEditData)
        response.brand_category && setBrandCategory(response.brand_category)
    }

    return (
        <Wrapper>
            <AddBrand
                productTypeList={productTypeList}
                setBrandData={setBrandData}
                brandData={brandData} setCreateClick={setCreateClick}
                components={components}
                setComponents={setComponents}
                productSelectDropdown={productSelectDropdown}
                setProductSelectDropdown={setProductSelectDropdown}
                setBrandCategory={setBrandCategory}
                brandCategory={brandCategory}
                brandEditID={brandEditID}
            />
        </Wrapper>
    )
}

export default AddBrands