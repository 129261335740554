import React, { useEffect, useState, useContext } from 'react';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import { Stepper, BottomNav } from '../../../../components/common/Stepper/Stepper'
import service from '../../../../api/services';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router';
import { BrandData, ProductType } from './interface';
import { HeaderHeading } from '../../../../Context'
const { BrandAllocation } = organisms
const Allocation = () => {
    const { addToast } = useToasts();
    const navigate = useNavigate()
    const [step, setStep] = useState(0)
    const [error, setError] = useState('')
    const [brandList, setBrandList] = useState<BrandData | any>([])
    const [storeList, setStoreList] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])
    const [selectedStore, setSelectedStore] = useState([])
    const [search, setSearch] = useState('');
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const steps = [
        'Brand Allocation',
        'Store Selection',
        'Summarised',
    ];

    const handleNextStep = () => {
        const isValid = validate()
        setSearch('')
        if (isValid) {
            if (step !== 1) {
                getStoreList()
            }
            return true
        }
        return false
    }
    const getBrandList = async () => {
        try {
            let response = await service.productData.brandListingService({});
            if (response.status === 200) {
                const updatedData = response.data.data.map((brand: BrandData) => {
                    const updatedProductTypes = brand.product_types.map((productType: ProductType) => ({
                        ...productType,
                        brand_discount: productType.brand_discount === (undefined || null) ? 0 : productType.brand_discount,
                        brand_landing_cost: productType.brand_landing_cost === (undefined || null) ? 0 : productType.brand_landing_cost,
                        brand_margin: productType.brand_margin === (undefined || null) ? 0 : productType.brand_margin,
                        specx_landing_cost: productType.specx_landing_cost === (undefined || null) ? 0 : productType.specx_landing_cost,
                    }));

                    return {
                        ...brand,
                        product_types: updatedProductTypes,
                    };
                });
                setBrandList(updatedData);
            }
        } catch (error) {
            console.error(error);
        }
    };


    const getStoreList = async () => {
        try {
            let response = await service.allocation.getAllStore()
            if (response.status === 200) {
                setStoreList(response?.data?.data)
            }
        } catch (error: any) {
            console.log(error)
        }
    }
    const validate = () => {
        let valid = false
        if ((step === 0) && (selectedBrand?.length > 0)) {
            valid = true
            setError("")
        } else if ((step === 1) && (selectedStore?.length > 0)) {
            valid = true
            setError("")
        }
        else {
            setError("Please Select Data")
            addToast("Please Select Data", {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        return valid
    }

    const getPayload = () => {
        let brandData: any = []
        let storeData: any = []
        selectedBrand && selectedBrand.map((item: BrandData) => {
            let productType: any = []
            if (item.product_types) {
                item.product_types.map((data: ProductType) => {
                    productType.push({
                        product_type_name: data.product_type.name,
                        brand_margin: data?.brand_margin,
                        brand_discount: data?.brand_discount,
                        brand_landing_cost: data?.brand_landing_cost,
                        product_type_id: data?.product_type.id,
                        specx_landing_cost: data?.specx_landing_cost
                    })
                })
            }
            brandData.push({
                brand_id: item.id,
            brand_status: item.brand_status || 1,
                product_types: productType
            })
        }
        )
        selectedStore && selectedStore.map((item: BrandData) => {
            storeData.push(item.id)
        })
        handleSubmit(brandData, storeData)
    }

    const handleSubmit = async (brand: BrandData, store: string) => {
        let payload = {
            brands: brand,
            stores: store
        }
        try {
            let response = await service.allocation.add(payload)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate('/store/brand/allocation-store')
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        setHeader("Store - Brand Allocation")
        setSidebar(false)
        getBrandList()
    }, [])

    const handleCancel = () => {
        if (step > 0) {
            setStep(0);
            setSelectedBrand([])
            setSelectedStore([])
        }
    };
    return (
        <Wrapper>
            <Stepper
                steps={steps}
                step={step}
            />
            <BrandAllocation
                brandList={brandList}
                setBrandList={setBrandList}
                step={step}
                storeList={storeList}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                selectedStore={selectedStore}
                setSelectedStore={setSelectedStore}
                error={error}
                search={search}
                setSearch={setSearch}
            />
            {/* <div className='mt-20 text-center'>
                {error && <span className="error-text fs-5">{error}</span>}
            </div> */}
            <BottomNav
                handleNextStep={() => handleNextStep()}
                steps={steps}
                handleSubmit={() => getPayload()}
                setStep={setStep}
                step={step}
                handleCancel={() => handleCancel()}
            />
        </Wrapper>
    )
}

export default Allocation