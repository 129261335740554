import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import { BrandData, StoreBrand, StoreEditData } from '../BrandAllocation/interface';
import { HeaderHeading } from '../../../../Context'
import constant from '../../../../constants/constant';
const { EditBrandAllocation } = organisms
const EditBrand = () => {
    const params = useParams()
    const { addToast } = useToasts();
    const [brandEditData, setBrandEditData] = useState<BrandData | any>([])
    const [selectedBrand, setSelectedBrand] = useState<BrandData | any>([])
    const [search, setSearch] = useState('');
    const [buttonClick, setButtonClick] = useState(false)
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    let storeEditID = params.id || ''
    const getBrandbyID = async () => {
        try {
            let response = await service.allocation.getDetailById(storeEditID)
            if (response.status === 200) {
                const modifiedResponse = response?.data?.data?.brands?.map((brand: StoreBrand) => ({
                    id: brand.id,
                    brand_name: brand.brand_name,
                    brand_status: brand.store_brands.brand_status,
                    product_types: brand.store_brands.store_brand_product_type_data
                }));
                let storeObj: StoreEditData = {
                    brands: modifiedResponse,
                    id: response?.data?.data.id,
                    store_id: response?.data?.data.store_id_display,
                    store_name: response?.data?.data.store_name,
                    deposit_amount: response?.data?.data.store_accounts[0]?.balance
                }
                setBrandEditData(storeObj)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async () => {
        let payload = {
            brand_status: selectedBrand.brand_status || constant.STORE_BRAND_STATUS[0].value,
            product_types: selectedBrand.product_types
        }
        try {
            let response = await service.allocation.editBrandDetailById(storeEditID, selectedBrand.id, payload)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                getBrandbyID()
            }
        } catch (error) {
            console.log(error)
        }
        setButtonClick(false)
    }

    const removeBrand = async (brandId: string) => {
        try {
            let response = await service.allocation.deleteBrandDetailById(storeEditID, brandId)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                getBrandbyID()
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setHeader("Store - Allocated Brands")
        setSidebar(true)
        setSearch('')
        getBrandbyID()
    }, [])

    useEffect(() => {
        if (buttonClick) {
            handleSubmit()
        }
    }, [buttonClick])
    return (<Wrapper>
        <EditBrandAllocation brandEditData={brandEditData} setBrandEditData={setBrandEditData} search={search}
            setSearch={setSearch} setButtonClick={setButtonClick}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            removeBrand={(brandId: string) => removeBrand(brandId)}
        />
    </Wrapper>)
}
export default EditBrand